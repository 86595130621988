import React from "react"
import { Link } from "gatsby"
import Nav from "../components/nav"
import {SubscribeOnly} from "../components/subscribe"
import SEO from "../components/seo"
import CarbonAd from "../components/carbonad";

const Subscribe = () => (
  <div className="qn-bg-dark1">
    <SEO title="About"/>
    <Nav/>
    <div className="center mw9 mt16-l mt8 ">
      <div className="fl w-70-l w-100-m w-100 pl32-l pr32-l ph32-m ph24 qn-cl-white qn-bg-dark1">
        <h2 className="inter f3-l f4 mb0 mt0 qn-lh-tight qn-ls-condensed fw7 mb24">Subscribe to our newsletter for weekly design highlights!</h2>
        <p className="ibmplexmono f6-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw3 mb16">I promise, I won't spam you.</p>
        <SubscribeOnly/>
      </div>

      {/* STICKY SIDEBAR */}
      <div className="fl db-l dn w-30-l w-0 white sticky-sidebar pl24-l pr32-l">
        {/*<ul className="qn-category-tab-desktop db mb0 pb8">
          <li className="db">
            <Link to="/all">
              <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-white qn-transition-cl">All</p>
            </Link>
          </li>
          {data.getcategories.group.map(({ edges }) => (
          <li className="db mr32">
            <Link to={`/category/${slugify(edges[0].node.category.title,{lower: true})}`}><p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">{edges[0].node.category.title}</p></Link> 
          </li>   
          ))}
          </ul> <hr className="DividerNormal bb qn-br-dark2 mt32"/> */}
        <ul className="qn-category-tab-desktop db mb0 pb8">
          <li className="db">
            <Link to="/">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">All</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/design">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Design</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/research">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Research</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/process">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Process</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/ux-writing">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">UX Writing</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/personal-growth">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Personal Growth</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/leadership">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Leadership</p>
            </Link>
          </li>

          <li className="db">
            <div className="dropdown">
              <button className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl dropbtn">More...</button>
                <div className="dropdown-content">
                  <Link to="/category/career">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Career</p>
                  </Link>
                  <Link to="/category/creativity">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Creativity</p>
                  </Link>
                  <Link to="/category/design-system">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Design System</p>
                  </Link>
                  <Link to="/category/freelance">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Freelance</p>
                  </Link>
                  <Link to="/category/product">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Product</p>
                  </Link>
                  <Link to="/category/prototyping">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Prototyping</p>
                  </Link>
                </div>
            </div>
          </li>
          </ul> 
          
          <hr className="DividerNormal bb qn-br-dark2"/>
          <ul className="qn-category-tab-desktop db">
            <li className="dib mr8 mb0">
              <Link to="/subscribe">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Subscribe</p>
              </Link>
            </li>
            <li className="dib mr8 mb0">
            ·
            </li>
            <li className="dib mr8 mb0">
              <Link to="/about">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">About</p>
              </Link>
            </li>
            <li className="dib mr8 mb0">
            ·
            </li>
            <li className="dib mb24">
              <a href="https://chrome.google.com/webstore/detail/insights-hunt/mcpkoiegngiibjaedneiglaepmfggdhb" rel="noreferrer" target="_blank">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Extension</p>
              </a>
            </li>
          </ul>
          <CarbonAd/>
        </div>
        {/* STICKY SIDEBAR */}
      <div className="cb"></div>
    </div>
    
  </div>
)

export default Subscribe
